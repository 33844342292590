import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"disabled":_vm.item.disabled}},[(_vm.imageModule)?_c(VImg,{attrs:{"height":"160","width":"100%","src":_vm.imageModule}}):_vm._e(),_c(VCardText,{staticClass:"d-flex align-center mt-5"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text mt-1",attrs:{"size":"54","rounded":"","color":"primary"}},[_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.avatarText(_vm.item.name))+" ")])])]),_c('div',{staticClass:"ms-3"},[_c('h4',{staticClass:"text-base font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.resolvedNameModule(_vm.item.name))+" ")]),_c('p',{staticClass:"text-xs mb-0"},[_vm._v(" "+_vm._s(_vm.item && _vm.item.description ? _vm.item.description : "")+" ")])])],1),_c(VCardText,[_c(VDivider)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-semibold text--primary mb-1"},[_vm._v("Processos")])]),_vm._l((_vm.icons),function(icon,index){return _c(VCol,{key:icon.description,staticClass:"text-center",attrs:{"cols":"3","value":("icon-" + index)}},[_c('div',[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(icon.icon)+" ")])],1),_c('span',{staticClass:"text-no-wrap text-sm text--primary"},[_vm._v(" "+_vm._s(icon.description)+" ")])])})],2)],1),_c(VCardText,[_c(VDivider)],1),_c(VCardText,[_c(VBtn,{attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.redirect(_vm.item)}}},[_c('span',{staticClass:"me-1"},[_vm._v("Acessar")]),_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" mdi-arrow-right ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }