<template>
  <div>
    <!-- Caso tenha Childrens -->
    <v-row
      v-if="
        module && module.children !== undefined && module.children.length > 0
      "
    >
      <v-col
        sm="6"
        md="4"
        cols="12"
        v-for="(modulo, index) in module.children"
        :key="index"
        :value="`item-${modulo}`"
      >
        <CardModules :item="modulo"></CardModules>
      </v-col>
    </v-row>
    <!-- Caso não tenha -->
    <v-row v-else>
      <v-col cols="12">
        <component :is="module.component"></component>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardModules from "./components/CardModules.vue";
export default {
  components: {
    CardModules,
  },
  // As props são pegas nos parametros da rota
  props: ["modulo", "children"],
  methods: {
    /**
     * @description Procura pelo módulo desejado, se o dado não tiver na primeira camada o mesmo irá procurar se tem children e então irá renderiza-los independentemente do tamanho da arvore.
     * - O array de objetos devem manter um padrão;
     *  - Objetos na raiz não tem rootNode, já os filhos, netos... dele terão sempre o rootNode com o nome do objeto raiz
     *     -- Exemplo: { routeName: 'pco' ... children: [{ rootNode: 'pco', routeName: 'escalas' }] }
     *  - rootNode e routeName sempre deverão ser letras minusculas.
     * - Todos os objetos no array devem ter o breabcrumb citado o caminho completo que percorreu
     * - Está função faz uma busca inteligente e para evitar maior processamento e percursão por dados não necessários na arvore, é então utilizado o rootName
     * que tem como objetivo pesquisar em uma estrutura certeira da arvore e não desperdiçar recursos em lugares que não encontrará.
     *
     * @example searchModule(array multidimensional, valor que procura (procura pelo valor no routeName), valor rootNode (nome do objeto raiz))
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    searchModule(node, value, rootNode) {
      for (let i = 0; i < node.length; i++) {
        if (node[i].routeName == value) return node[i];

        if (
          node[i] &&
          node[i].children != undefined &&
          node[i].children.length > 0 &&
          node[i].children[0].rootNode == rootNode
        ) {
          return this.searchModule(node[i].children, value, rootNode);
        }
      }
    },
  },
  computed: {
    modulos: {
      get() {
        return this.$store.getters["empresas/getModulosEmpresa"];
      },
    },
    module() {
      const isChildren = this.children ? this.children : this.modulo;

      const module = this.searchModule(this.modulos, isChildren, this.modulo);

      const data = {
        name: module.name,
        breadcrumbs: module.breadcrumbs,
      };

      this.$store.dispatch("empresas/setDatasLayoutModulos", data);

      return module;
    },
  },
};
</script>

<style></style>
