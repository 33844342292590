<template>
  <v-card :disabled="item.disabled">
    <v-img
      height="160"
      width="100%"
      v-if="imageModule"
      :src="imageModule"
    ></v-img>

    <v-card-text class="d-flex align-center mt-5">
      <v-avatar
        size="54"
        rounded
        color="primary"
        class="v-avatar-light-bg primary--text mt-1"
      >
        <div>
          <span class="font-weight-medium">
            {{ avatarText(item.name) }}
          </span>
        </div>
      </v-avatar>

      <div class="ms-3">
        <h4 class="text-base font-weight-semibold">
          {{ resolvedNameModule(item.name) }}
        </h4>
        <p class="text-xs mb-0">
          {{ item && item.description ? item.description : "" }}
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <p class="font-weight-semibold text--primary mb-1">Processos</p>
        </v-col>
        <v-col
          class="text-center"
          cols="3"
          v-for="(icon, index) in icons"
          :key="icon.description"
          :value="`icon-${index}`"
        >
          <div>
            <v-icon size="30">
              {{ icon.icon }}
            </v-icon>
          </div>
          <span class="text-no-wrap text-sm text--primary">
            {{ icon.description }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text>
      <v-btn @click="redirect(item)" block color="primary">
        <span class="me-1">Acessar</span>
        <v-icon size="20"> mdi-arrow-right </v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    imageModule: { type: String, default: "" },
    avatar: { type: String, default: "" },
    item: {
      type: Object,
      default: {
        name: "",
        description: "",
        url: "",
        breadcrumbs: [],
      },
    },
    descripitionModule: { type: String, default: "" },
    component: { type: String, default: "" },
  },
  name: "CardModules",
  components: {},
  data() {
    return {
      icons: [
        { icon: "mdi-star-outline", description: "Proposta" },
        { icon: "mdi-check-circle-outline", description: "Contrato" },
        { icon: "mdi-account-outline", description: "Implatação" },
        { icon: "mdi-dots-horizontal", description: "Produção" },
      ],
    };
  },
  methods: {
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    resolvedNameModule(value) {
      if (!value) return "";
      const nameArray = value;
      return nameArray.charAt(0).toUpperCase() + nameArray.slice(1);
    },
    redirect(item) {
      return this.$router.push({
        name: "empresas-adm-modulos",
        params: {
          uuid: this.$route.params.uuid,
          modulo: item.rootNode ? item.rootNode : item.routeName,
          children: item.rootNode ? item.routeName : null,
        },
      });
    },
  },
  computed: {},
  created() {},
};
</script>
